import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import { parse } from "flatted"

const SpeakerTemplate = ({ pageContext, data, location }) => {
  const speaker = parse(pageContext.speaker)
  const siteTitle = data.site.siteMetadata?.title || `Title`

  console.log(speaker)
  return (
    <Layout location={location} title={siteTitle}>
      <p>
        No blog posts found. Add markdown posts to "content/blog" (or the
        directory you specified for the "gatsby-source-filesystem" plugin in
        gatsby-config.js).
      </p>
      <h1>{speaker.name}</h1>
      {speaker.sessions.map(session => (
        <p>{session.name}</p>
      ))}
    </Layout>
  )
}

export default SpeakerTemplate

export const pageQuery = graphql`
  query SpeakerDataBySlug {
    site {
      siteMetadata {
        title
      }
    }
  }
`
